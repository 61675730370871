export function formatDate(dateString) {
	const options = { day: "numeric", month: "long", year: "numeric" };
	const date = new Date(dateString);
	const day = date.getDate();
	const formattedDay =
		day +
		(day % 10 === 1 && day !== 11
			? "st"
			: day % 10 === 2 && day !== 12
			? "nd"
			: day % 10 === 3 && day !== 13
			? "rd"
			: "th");
	return date.toLocaleDateString("en-US", options).replace(`${day}`, formattedDay);
}

export function formatTime(dateString) {
	const date = new Date(dateString);
	const hours = date.getHours();
	const minutes = date.getMinutes();

	return `${hours}:${minutes.toString().length === 1 ? `0${minutes}` : minutes}`;
}

export function convertIstTime(dateString) {
	const utcTimestamp = new Date(dateString);
	utcTimestamp.setHours(utcTimestamp.getHours() + 5);
	utcTimestamp.setMinutes(utcTimestamp.getMinutes() + 30);
	return utcTimestamp;
}

export function formatDateToDDMMYYYYHHMM12Hr(date) {
	const dd = String(date.getDate()).padStart(2, "0");
	const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0
	const yyyy = date.getFullYear();
	const hh = date.getHours();
	const ampm = hh >= 12 ? "PM" : "AM";
	const hh12 = hh % 12 || 12; // Convert 0 to 12 in 12-hour format
	const mm2 = String(date.getMinutes()).padStart(2, "0");

	return `${dd}/${mm}/${yyyy} ${hh12}:${mm2} ${ampm}`;
}

export function formatDateTime(dateString) {
	var istTime = convertIstTime(dateString);
	return formatDateToDDMMYYYYHHMM12Hr(istTime);
}
