import { host } from "./constants";
import * as ids from "./constants";

export const getAPIURL = (id) => host.hostName + ids[id];
export const reasonsForUnsubscribe = [
  "I don't like the offer given",
  "I am taking a similar product elsewhere",
  "I can't afford the product anymore",
  "Poor experience with customer service",
  "Other",
];
