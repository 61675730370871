import { useEffect, useMemo, createContext, useState } from "react";
import jwtDecode from "jwt-decode";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState({
		isAuthenticated: false,
		userData: {}
	});
	const value = useMemo(() => ({ auth, setAuth }), [auth]);

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token)
			try {
				const tokenObj = JSON.parse(token);
				const userData = jwtDecode(tokenObj.id_token)
				setAuth({
					isAuthenticated: true,
					userData
				})
			}
			catch (error) {
				console.log(error);
			}
	}, [])

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
