import { useContext } from "react";
import { DataContext } from "../context/DataContext";

const useGlobalData = () => {
    const context = useContext(DataContext);

    if (!context) {
        throw Error('Use Auth Context to use these state');
    }

    return context;
};

export default useGlobalData;