import { lazy, Suspense } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import SiteNavbar from "./layout/Navbar";
import DataProvider from "./context/DataContext";
import { useCheckIsLoggedIn } from "./utils/loginUtils";

import "./App.css";
import useGlobalData from "./hooks/useGlobalData";
import { useState } from "react";
import { useEffect } from "react";
import { host } from "./constants/constants";
import { data } from "./hooks/useRazorPay";
import checkRequests from "./components/CheckRequests";
import { sopData } from "./pages/user/MySOP";

const Home = lazy(() => import("./pages/common/Home"));
const Faq = lazy(() => import("./pages/common/faq/Faq"));
const ContactUs = lazy(() => import("./pages/common/contact-us/ContactUs"));
const Resources = lazy(() => import("./pages/common/resources/Resources"));
const Article = lazy(() => import("./pages/common/resources/Article"));
const AboutUs = lazy(() => import("./pages/common/about-us/AboutUs"));
const Page404 = lazy(() => import("./pages/common/Page404"));
const ConfirmSignUp = lazy(() =>
  import("./pages/common/sign-up/ConfirmSignUp")
);
const ForgotPassword = lazy(() =>
  import("./pages/common/forgot-password/ForgotPassword")
);
const ConfirmForgotPassword = lazy(() =>
  import("./pages/common/forgot-password/ConfirmForgotPassword")
);
const PrivacyPolicy = lazy(() => import("./pages/common/PrivacyPolicy"));
const TermsAndConditions = lazy(() =>
  import("./pages/common/TermsAndConditions")
);
const Careers = lazy(() => import("./pages/common/Careers"));
const SampleSOP = lazy(() => import("./pages/sop/SampleSOP"));
const SOPDetails = lazy(() => import("./pages/sop/SOPDetails"));
const SOPList = lazy(() => import("./pages/sop/SOPList"));
const ManageSOP = lazy(() => import("./pages/sop/ManageSOP"));
const ManageSopAccess = lazy(() => import("./pages/user/ManageSopAccess"));
const Login = lazy(() => import("./pages/common/Login"));
const SignUp = lazy(() => import("./pages/common/sign-up/SignUp"));
const Cart = lazy(() => import("./pages/user/Cart"));
const Payment = lazy(() => import("./pages/user/Payment"));
const Orders = lazy(() => import("./pages/user/Orders"));
const ManageUsers = lazy(() => import("./pages/user/ManageUsers"));
const MyProfile = lazy(() => import("./pages/user/MyProfile"));
const CompanyProfile = lazy(() => import("./pages/user/CompanyProfile"));
const MySOP = lazy(() => import("./pages/user/MySOP"));
const Subscriptions = lazy(() => import("./pages/user/Subscriptions"));
const Unsubscribe = lazy(() => import("./pages/user/Unsubscribe"));
const ViewSOP = lazy(() => import("./pages/user/ViewSOP"));
const SuccessPage = lazy(() => import("./pages/common/SuccessPage"));
const LoginSummary = lazy(() => import("./pages/user/LoginSummary"));

function PrivateWrapper() {
  const isLoggedIn = useCheckIsLoggedIn(false);
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}

function Router() {
  const { globalData } = useGlobalData();
  const isLoading = globalData?.isLoading;
  return (
    <>
      {isLoading && (
        <div className="loading">
          <div id="loading-wrapper">
            <div id="loading-text"></div>
            <div id="loading-content"></div>
          </div>
        </div>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route index element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<SignUp />}></Route>
          <Route path="/confirm-signup" element={<ConfirmSignUp />}></Route>
          <Route path="/faq" element={<Faq />}></Route>
          <Route path="/sample-sop" element={<SampleSOP />}></Route>
          <Route path="/sop-list" element={<SOPList />}></Route>
          <Route path="/sop-details/:id" element={<SOPDetails />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/resources" element={<Resources />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/careers" element={<Careers />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route
            path="/confirm-forgot-password"
            element={<ConfirmForgotPassword />}
          ></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          ></Route>
          <Route path="/success" element={<SuccessPage />}></Route>
          <Route path="/cart" element={<Cart />}></Route>
          <Route path="/article/:id" element={<Article />}></Route>
          <Route path="/unsubscribe/:id" element={<Unsubscribe />}></Route>
          <Route element={<PrivateWrapper />}>
            <Route path="/my-sop" element={<MySOP />}></Route>
            <Route path="/orders" element={<Orders />}></Route>
            <Route path="/manage-users" element={<ManageUsers />}></Route>
            <Route path="/my-profile" element={<MyProfile />}></Route>
            <Route path="/company-profile" element={<CompanyProfile />}></Route>
            <Route path="/subscriptions" element={<Subscriptions />}></Route>
            <Route path="/view-sop" element={<ViewSOP />}></Route>
            <Route path="/manage-sop" element={<ManageSOP />}></Route>
            <Route
              path="/manage-sop-access"
              element={<ManageSopAccess />}
            ></Route>
            <Route path="/payment" element={<Payment />}></Route>
            <Route path="/login-summary" element={<LoginSummary />}></Route>
          </Route>
          <Route path="/*" element={<Page404 />}></Route>
        </Routes>
      </Suspense>
    </>
  );
}

function App(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const location = window.location.hostname;
    let mode = "prod";
    if (location.includes("localhost")) mode = "local";
    else if (location.includes("dev")) mode = "dev";
    import(`./config/appconfig.${mode}.json`)
      .then((res) => {
        const d = res.default;
        host.hostName = d.apiHost;
        data.key = d.razorpayKey;
        sopData.usage = d.usage;
        sopData.idletime = d.idletime;
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (isLoading)
    return (
      <div className="loading">
        <div id="loading-wrapper">
          <div id="loading-text"></div>
          <div id="loading-content"></div>
        </div>
      </div>
    );

  return (
    <>
      <AuthProvider>
        <DataProvider>
          <Router />
          <div className="App">
            <SiteNavbar />
            <Outlet />
          </div>
        </DataProvider>
      </AuthProvider>
    </>
  );
}

export default checkRequests(App);
