import { useMemo, createContext, useState, useEffect } from "react";
import { cartData, moveGuestCartToUser } from "../utils/cartUtils";
import useAuth from "../hooks/useAuth";

export const DataContext = createContext({});

export const DataProvider = ({ children }) => {
	const [globalData, setGlobalData] = useState({});
	const { auth: { isAuthenticated } } = useAuth();

	useEffect(() => {
		const cb = data => setGlobalData(prevState => ({ ...prevState, cartCount: data.length }));
		if (isAuthenticated) moveGuestCartToUser(cb)
		else cartData(cb)
	}, [isAuthenticated])


	const value = useMemo(() => ({ globalData, setGlobalData }), [globalData]);

	return (
		<DataContext.Provider value={value}>
			{children}
		</DataContext.Provider>
	);
};

export default DataProvider;
