import useGlobalData from "./useGlobalData";

export const useLoader = () => {
	const { setGlobalData } = useGlobalData();

	const startLoader = () => {
		setGlobalData((prev) => ({ ...prev, isLoading: true }));
	};

	const stopLoader = () => {
		setGlobalData((prev) => ({ ...prev, isLoading: false }));
	};

	return { startLoader, stopLoader };
};
