import axios from "axios";
import { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Modal, ModalBody, ModalHeader, Row, Table, ModalFooter, Button, Alert,Toast, ToastContainer } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { useLoader } from "../../hooks/useLoader";
import { getAPIURL } from "../../constants";
import { getHeaders } from "../../utils/loginUtils";
import { alertType } from "../../constants/constants";

const CloneSOP = ({ docData, onCancel, onSaveSuccess, otherData }) => {
    const [data, setData] = useState(docData);
    const { auth: { userData } } = useAuth();
    const {startLoader, stopLoader} = useLoader();
    const { ImgLogo, colorMap } = otherData;
    const [errorMessage, setErrorMessage] = useState('');
    const [alert, setAlert] = useState({
		type: "Success !",
		message: "",
		show: false,
	  });
	const showAlert = (type, message) => {
		setAlert({
		  type: type,
		  message: message,
		  show: true,
		});
	  };
	
	const hideAlert = () => {
		setAlert({
		  show: false,
		});
	  };
    useEffect(() => {
        if(!docData) {
            setData(null)
            return;
        }; 
        setData({
            ...docData,
            sop_header_blocks: docData.sop_header_blocks.map(el => ({ ...el, text_content: '' })),
            sop_body_blocks: docData.sop_body_blocks.map(el => ({ ...el, text_content: '' })),
            remarks: ''
        });
    }, [docData]);

    const updatePage = () => {
        const { sop_header_blocks, sop_body_blocks, body_section_title, remarks, id, user_sop_document_id } = data;
        startLoader();
        axios.post(getAPIURL("UPDATE_SOP_PAGE"), {
            created_by: userData.id,
            id,
            created_at: new Date().toISOString(),
            page_num: 2,
            active: true,
            sop_document_id: 0,
            remarks,
            sop_header_blocks,
            sop_body_blocks,
            body_section_title,
            user_sop_document_id
        }, {headers: getHeaders()}).then( res => {
            onSaveSuccess();
            showAlert(alertType.success,"Template created successfully");
            setTimeout(hideAlert,20000)
            onCancel();
        }).catch(
            error => {
                let error_received = "Oops! Something went wrong.";
				if (error.response) {
					error_received = error.response.data.detail;
				}
                showAlert(alertType.error,error_received);
                setTimeout(hideAlert,20000)

            }
        ).finally(stopLoader);
    }

    const onHeaderChange = (e) => {
        setData(prev => {
            const dataClone = structuredClone(prev);
            dataClone.sop_header_blocks[e.target.name].text_content = e.target.value;
            return dataClone;
        })
    }

    const onBodyChange = (e) => {
        setData(prev => {
            const dataClone = structuredClone(prev);
            dataClone.sop_body_blocks[e.target.name].text_content = e.target.value;
            return dataClone;
        })
    }

    return (
        <>
        <ToastContainer className="m-3" position="top-center" style={{ zIndex: 20000, backgroundColor: "#fff" }}>
        <Toast show={alert.show} onClose={hideAlert}>
          <Toast.Header>
            <strong className="me-auto">{alert.type}</strong>
          </Toast.Header>
          <Toast.Body>{alert.message}</Toast.Body>
        </Toast>
      </ToastContainer>
        <Modal show={!!data} closeButton onHide={() => onCancel} dialogClassName="w-90">
            <ModalHeader>
                <h5>
                    Copy Template
                </h5>
            </ModalHeader>
            <ModalBody>
                <Row className="mb-2" key={data?.id}>
                    <Col md={10}>
                        <Row>
                            <Col>
                                <Table className="mb-0">
                                    <thead>
                                        <tr>
                                            {data?.sop_header_blocks?.map((doc) => (
                                                <th key={doc.title}>{doc?.title}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {data?.sop_header_blocks?.map((doc, i) => (
                                                <td key={doc.title}>
                                                    <FloatingLabel label={doc?.title} className>
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder={""}
                                                            style={{ minHeight: "200px", height: "100%" }}
                                                            required
                                                            defaultValue={doc?.text_content}
                                                            onChange={onHeaderChange}
                                                            maxLength={200}
                                                            name={i}
                                                        />
                                                    </FloatingLabel>
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2} className="ps-0">
                        <div className="mySOP-logo">
                            <img className="d-block w-100" src={ImgLogo} alt="Logo" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={10}>
                        <Row>
                            <Col>
                                <div className="steps-head">{data ? data.body_section_title : ""}</div>
                            </Col>
                        </Row>
                        <Row className="arrow-steps clearfix p-2 me-2">
                            {data?.sop_body_blocks?.map((e, i) => (
                                <Col className={`step ${colorMap[i % 5]}`} key={i}>
                                    <span>{e.title}</span>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <Col className="mb-2">
                                <b>Steps to follow / Work instructions</b>
                            </Col>
                        </Row>
                        <Row className="steps-divider me-2">
                            {data?.sop_body_blocks?.map((doc, i) => (
                                <Col>
                                    <FloatingLabel label={doc?.title + "*"} className>
                                        <Form.Control
                                            as="textarea"
                                            placeholder={""}
                                            style={{ minHeight: "500px", height: "100%" }}
                                            required
                                            defaultValue={doc?.text_content}
                                            onChange={onBodyChange}
                                            maxLength={500}
                                            name={i}
                                        />
                                    </FloatingLabel>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col md={2} className="ps-0">
                        <Row>
                            <Col className="mb-2">
                                <div className="steps-head">Remarks</div>
                            </Col>
                        </Row>
                        <Row>
                            <FloatingLabel label="Remarks*" className>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    style={{ minHeight: "200px", height: "100%" }}
                                    required
                                    defaultValue={data?.remarks}
                                    onChange={e => setData(prev => ({ ...prev, remarks: e.target.value }))}
                                />
                            </FloatingLabel>
                        </Row>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button onClick={updatePage} type="submit">Save</Button>
                <Button onClick={onCancel} type="reset">Cancel</Button>
            </ModalFooter>
        </Modal>
        </>
    )
}

export default CloneSOP;