import { Row, Col, Form, Button, Container, Alert } from "react-bootstrap";
import IconFacebook from "../assets/images/ic_facebook.png";
import IconTwiteer from "../assets/images/ic_twitter.png";
import IconLinkedin from "../assets/images/ic_linkedin.png";
import IconWhatsapp from "../assets/images/ic_whatsapp.svg";
import { useRef, useState } from "react";
import axios from "axios";
import { getAPIURL } from "../constants";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export const Footer = () => {
	const emailRef = useRef();
	const [errorMessage, setErrorMessage] = useState("");
	const [showDangerAlert, setShowDangerAlert] = useState(false);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);

	useEffect(() => {
		if (showSuccessAlert) setTimeout(() => setShowSuccessAlert(false), 3000);
	}, [showSuccessAlert]);

	const onSubmitSubscribe = async (event) => {
		event.preventDefault();
		var data = { email: emailRef.current.value, active: true };

		axios
			.post(getAPIURL("URL_SUBSCRIBE"), data)
			.then((response) => {
				setShowSuccessAlert(true);
			})
			.catch((ex) => {
				if (ex.response && ex.response.data && ex.response.data.detail) {
					setErrorMessage(ex.response.data.detail);
					setShowDangerAlert(true);
				}
			});
	};

	return (
		<>
			<div className='container-fluid="true" footer'>
				<Row className='tab-device'>
					<Col xs={12} md={3} className='f-1 mb-4'>
						<h5>Quick Links</h5>
						<Container fluid='true'>
							<Row>
								<Col md={5} className='pe-0'>
									<ul className='list-group'>
										<li className=''>
											<Link to='/about-us' title='About Us'>
												About Us
											</Link>
										</li>
										<li className=''>
											<Link to='/sop-list' title='SOPs'>
												SOPs
											</Link>
										</li>
										<li className=''>
											<Link to='/resources' title='Resources'>
												Resources
											</Link>
										</li>
										<li className=''>
											<Link to='/contact-us' title='Contact Us'>
												Contact Us
											</Link>
										</li>
									</ul>
								</Col>
								<Col md={7}>
									<ul className='list-group'>
										<li className=''>
											<Link to='/careers' title='Careers'>
												Careers
											</Link>
										</li>
										<li className=''>
											<Link to='/faq' title='FAQs'>
												FAQs
											</Link>
										</li>
										<li className=''>
											<Link to='/terms-and-conditions' title='Terms and Conditions'>
												Terms and Conditions
											</Link>
										</li>
										<li className=''>
											<Link to='/privacy-policy' title='Privacy Policy'>
												Privacy Policy
											</Link>
										</li>
									</ul>
								</Col>
							</Row>
						</Container>
					</Col>
					<Col xs={12} md={3} className='f-2 mb-4'>
						<h5>Office Location</h5>
						<h6>KD Practice Consulting Private Limited</h6>
						<p>G6, Padmavati Tower CHS LTD, Balaji Nagar, Bhayandar(W), Thane-401101</p>
						<p> Mon-Fri: 09:00 am - 05.00 pm</p>
					</Col>
					<Col xs={12} md={2} className='f-3 mb-4'>
						<h5>Contact Us</h5>
						<p>+ 91 7304490857</p>
						<p>
							<Link to='mailto:support@mysopguide.com'>support@mysopguide.com</Link>
						</p>
						<div>
							<Link to='https://www.facebook.com/mysopguide/' title='Facebook' target='blank'>
								<img className='me-2' src={IconFacebook} alt='Facebook' />
							</Link>
							<Link to='https://www.linkedin.com/showcase/mysopguide' title='Linkedin' target='blank'>
								<img className='me-2' src={IconLinkedin} alt='Linkedin' />
							</Link>
						</div>
					</Col>
					<Col xs={12} md={4} className='f-4 mb-4'>
						<Row>
							<Col md={12}>
								<h5>Stay Tuned</h5>
							</Col>
							<Col xs={12} sm={6} md={12}>
								<p>Subscribe to our newsletter and never miss our updates on SOPs, Resources, etc.</p>
							</Col>
							<Col xs={12} sm={6} md={12}>
								<Form onSubmit={onSubmitSubscribe}>
									<Form.Group as={Row} className='mb-2'>
										<Col xs='8' className='pe-0'>
											<Form.Control
												ref={emailRef}
												type='email'
												required
												minLength={5}
												maxLength={500}
												placeholder='Enter your email address'
												onChange={() => {
													setShowDangerAlert(false);
													setShowSuccessAlert(false);
												}}
											/>
											<Alert show={showDangerAlert} variant='danger' className='mt-3'>
												{errorMessage}
											</Alert>
											<Alert show={showSuccessAlert} variant='success' className='mt-3'>
												Thank you for subscription !
											</Alert>
										</Col>
										<Col xs='4'>
											<Button className='form-control' variant='secondary' type='submit'>
												Subscribe
											</Button>
										</Col>
									</Form.Group>
								</Form>
							</Col>
						</Row>
					</Col>
				</Row>
				<hr></hr>
				<Row>
					<Col xs={12} className=''>
						© mySOP Guide 2023 all rights reserved
					</Col>
				</Row>
			</div>
			<div className='whatappChat'>
				<Button variant='link' target='_blank' href='https://api.whatsapp.com/send?phone=7304490857'>
					<img className='me-2' src={IconWhatsapp} alt='Facebook' />
				</Button>
			</div>
		</>
	);
};

export default Footer;
