import { useRef, useState } from "react";
import { Nav, Navbar, Row, Col, Form, Button, Container, Dropdown } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ImgLogo from "../assets/images/sop_logo.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import useAuth from "../hooks/useAuth";
import { useRole } from "../hooks/useRole";
import useGlobalData from "../hooks/useGlobalData";
import { useEffect } from "react";
import axios from "axios";
import { getAPIURL } from "../constants";
import { getHeaders } from "../utils/loginUtils";

const hideNavBar = ["/login", "/signup", "/confirm-signup", "/forgot-password", "/success"];
const createNavObj = (to, label) => ({ to, label });

const defaultNav = [
	createNavObj("resources", "Resources"),
	createNavObj("about-us", "About Us"),
	createNavObj("sample-sop", "Sample SOP"),
	createNavObj("contact-us", "Contact Us"),
];

// please do not remove comments.
// TODO: add pages and then uncomment menu items
const customerAdminMenu = [
	createNavObj("sop-list", "SOPs"),
	createNavObj("my-sop", "My SOPs"),
	createNavObj("manage-users", "Users"),
	// createNavObj("manage-sop", "Manage Sop"),
	createNavObj("manage-sop-access", "Manage Sop"),
	createNavObj("subscriptions", "Subscription"),
	createNavObj("login-summary", "Login summary"),
	// createNavObj('reports', 'Reports'),
	{ ...createNavObj("", "More"), dropdown: defaultNav },
];

const customerMenu = [
	createNavObj("my-sop", "My SOPs"),
	//createNavObj("subscriptions", "Subscription"),
	{ ...createNavObj("", "More"), dropdown: defaultNav },
];

const getNavMenuConfig = (role) => {
	if (!role) return defaultNav;
	if (role === "customer-admin") return customerAdminMenu;
	return customerMenu;
};

export const SiteNavbar = () => {
	const [showSearch, setShowSearch] = useState(false);
	const [showNotification, setShowNotification] = useState(false);
	const [notifications, setNotifications] = useState([]);

	const navigate = useNavigate();
	const {
		auth: { isAuthenticated, userData },
		setAuth,
	} = useAuth();
	const { pathname } = useLocation();
	const { isCustomerAdmin } = useRole();
	const { globalData } = useGlobalData();
	const [sopList, setSopList] = useState([]);
	const [searchStr, setSearchStr] = useState("");
	const searchRef = useRef();

	useEffect(() => {
		if (showSearch) document.addEventListener("click", handleClickOutside, true);
		if (!showSearch) setSearchStr("");
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [showSearch]);

	function useOutsideNotification(ref) {
		useEffect(() => {
			/**
			 * Close toast if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowNotification(false);
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	const wrapperNotificationRef = useRef(null);
	useOutsideNotification(wrapperNotificationRef);

	const getUserNotifications = async () => {
		axios
			.get(getAPIURL("URI_USER_NOTIFICATION"), { headers: getHeaders() })
			.then((response) => {
				setNotifications(response.data);
				if (response.data && response.data.length > 0) {
					setShowNotification(true);
				} else setShowNotification(false);
			})
			.catch((error) => console.error(error));
	};

	const getSopUpdates = async () => {
		axios
			.get(getAPIURL("URI_USER_SOP_UPDATES"), { headers: getHeaders() })
			.then((response) => {
				getUserNotifications();
			})
			.catch((error) => console.error(error));
	};

	const getSopList = async () => {
		axios
			.get(getAPIURL("URL_SOP_LIST"))
			.then((response) => {
				setSopList(response.data);
			})
			.catch((error) => console.error(error));
	};

	useEffect(() => {
		if (!hideNavBar.includes(pathname)) {
			getUserNotifications();
			getSopList();
			getSopUpdates();
		}
	}, [pathname]);

	const onSearchClick = (e) => {
		e.preventDefault();
		setShowSearch((prevState) => !prevState);
	};

	const menu = getNavMenuConfig(userData?.role);

	const onLogoutClick = () => {
		localStorage.clear("token");
		setAuth((prev) => ({
			...prev,
			isAuthenticated: false,
			useData: {},
		}));
		// hack to do it in sequence
		setTimeout(() => {
			navigate("/home", { replace: true });
			window.location.href = window.location.href;
		}, 0);
	};

	const handleClickOutside = (event) => {
		if (searchRef.current && !searchRef.current.contains(event.target)) {
			setShowSearch(false);
		}
	};

	const clearAllNotifications = () => {
		axios
			.delete(getAPIURL("URI_CLEAR_NOTIFICATION"), { headers: getHeaders() })
			.then((response) => {
				getUserNotifications();
			})
			.catch((error) => console.error(error));
	};

	if (hideNavBar.includes(pathname)) return null;

	return (
		<div>
			<Navbar expand='lg' className='fixed-top'>
				<Container fluid className='g-0'>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Brand>
						<Link to='/home'>
							<img src={ImgLogo} height={44} alt='SOP' />
						</Link>
					</Navbar.Brand>
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='me-auto'>
							{menu.map((item) => {
								if (item.dropdown)
									return (
										<Dropdown key={item.to} style={{ color: "green" }}>
											<Dropdown.Toggle variant='link' id='dropdown-basic' align='end'>
												<Link className='nav-link'>{item.label}</Link>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												{item.dropdown.map((el) => (
													<Link key={el.to} className='dropdown-item' to={`/${el.to}`}>
														{el.label}
													</Link>
												))}
											</Dropdown.Menu>
										</Dropdown>
									);

								return (
									<Link key={item.to} className='nav-link' to={item.to}>
										{item.label}
									</Link>
								);
							})}
						</Nav>
					</Navbar.Collapse>
					<Nav className='d-flex nav-icons'>
						<div className='small-hide'>
							<Link className='nav-link' onClick={onSearchClick}>
								<i className='bi bi-search'></i>
							</Link>
							<Link className='nav-link' to='/faq'>
								<i className='bi bi-question-circle'></i>
							</Link>
							<Link className='nav-link me-3' to='/cart'>
								<i className='bi bi-cart2'></i>
								<span className='cart-order'>{globalData?.cartCount ?? 0}</span>
							</Link>
							{isAuthenticated && (
								<Link className='nav-link' onClick={(e) => setShowNotification((prev) => !prev)}>
									<i className='bi bi-bell'></i>
									{notifications?.length > 0 && <span className='cart-order'>{notifications.length}</span>}
								</Link>
							)}
							{isAuthenticated && (
								<Dropdown className='user-dropdown'>
									<Dropdown.Toggle variant='link' id='dropdown-basic' align='end'>
										<i className='bi bi-person-circle'></i>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										{isCustomerAdmin && (
											<Link className='dropdown-item' to='/orders'>
												My Orders
											</Link>
										)}
										<Link className='dropdown-item' to='/my-profile'>
											Update Profile
										</Link>
										<Link className='dropdown-item' to='/company-profile'>
											Company Profile
										</Link>
										<Link className='dropdown-item' onClick={onLogoutClick}>
											Logout
										</Link>
									</Dropdown.Menu>
								</Dropdown>
							)}

							{!isAuthenticated && (
								<Button
									variant='secondary'
									onClick={() => {
										navigate("/login", { replace: true });
									}}
								>
									Login
								</Button>
							)}
						</div>
					</Nav>
				</Container>
				{showNotification && (
					<Toast onClose={() => setShowNotification(false)} ref={wrapperNotificationRef}>
						<Toast.Body>
							<Row>
								<Col className='notification-cards'>
									<ul>
										{notifications.map((item) => {
											return (
												<li key={item.id}>
													<p className='mb-1'>{item.message}</p>
												</li>
											);
										})}
									</ul>
									<Button className='btn-bell-remove' onClick={() => clearAllNotifications()}>
										Remove All
									</Button>
								</Col>
							</Row>
						</Toast.Body>
					</Toast>
				)}
			</Navbar>
			{showSearch && (
				<div className='search-bar' ref={searchRef}>
					<Row>
						<Col>
							<Form className='d-flex'>
								<Form.Control
									type='search'
									placeholder='Search'
									className='search-icon'
									aria-label='Search'
									onChange={(e) => setSearchStr(e.target.value)}
								/>
							</Form>
							{searchStr &&
								sopList
									?.filter((el) => el.title.toLowerCase().includes(searchStr.toLowerCase()))
									?.map((el) => (
										<div className='mt-2'>
											<Link to={"/sop-details/" + el.id} onClick={() => setShowSearch(false)}>
												{el.title}
											</Link>
										</div>
									))}
						</Col>
					</Row>
				</div>
			)}
			<Container fluid className='deviceTabs'>
				<Row>
					<Col className='g-0'>
						<Link onClick={onSearchClick}>
							<i className='bi bi-search'></i>
						</Link>
					</Col>
					<Col className='g-0'>
						<Link to='/faq' title='Search'>
							<i className='bi bi-question-circle'></i>
						</Link>
					</Col>
					<Col className='g-0'>
						<Link title='Notification' onClick={(e) => setShowNotification((prev) => !prev)}>
							<i className='bi bi-bell'></i>
							<span className='cart-order'>10</span>
						</Link>
					</Col>
					<Col className='g-0'>
						<Link to='/' title='Search'>
							<i className='bi bi-file-earmark-text'></i>
						</Link>
					</Col>
					<Col className='g-0'>
						<Link to='/cart' title='Search'>
							<i className='bi bi-cart2'></i>
							<span className='cart-order'>{globalData?.cartCount ?? 0}</span>
						</Link>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default SiteNavbar;
