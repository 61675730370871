import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const useAuth = () => {
	const context = useContext(AuthContext);

	if (!context) {
		throw Error('Use Auth Context to use these state');
	}

	return context;
};

export default useAuth;
