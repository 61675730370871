export const host = {};

//Public Site content
export const URL_SITE_CONTENT = "/public/home";
export const URL_FAQS = "/public/faqs";
export const URL_BANNER_LIST = "/public/banners";
export const URL_TESTIMONIALS = "/public/testimonials";
export const URL_SAMPLE_SOP = "/public/sample-sop";
export const URL_SOP_LIST = "/public/sop-bundles";
export const URL_CONTACT_US = "/public/contact-us";
export const URL_CAREERS = "/public/careers";
export const URL_SUBSCRIBE = "/public/subscribe";
export const URL_UNSUBSCRIBE = "/public/unsubscribe";
export const URL_ARTICLES = "/public/articles";
export const URL_FEED = "/public/feeds";
export const URL_GET_ARTICLE = "/public/article";
export const URL_VIDEOS = "/public/videos";
//Login/Signup
export const URL_LOGIN = "/public/user/login";
export const URL_SIGNUP = "/public/user/signup";
export const URL_CONFIRM_SIGNUP = "/public/user/confirm-signup";
export const URL_INITIATE_FORGOT_PASSWORD = "/public/user/forgot-password";
export const URL_VALIDATE_CONFIRMATION_CODE =
  "/public/user/confirm-forgot-password";
export const URL_GET_IP_ADDRESS = "https://checkip.amazonaws.com/";
export const URL_ADD_TO_CART = "/user/cart/item";
export const URL_SOP_BUNDLE_DETAIL_BY_ID = "/public/sop-bundle/";
export const URL_GET_CART_ITEMS = "/user/cart";
export const URL_GET_PROMOCODES = "/public/promocode";
export const URL_COMPUTE_CART_PRICE = "/compute-price";
export const URL_GET_GUEST_CART_ITEMS = "/public/guest-cart";
export const URL_GET_GUEST_CART_SUMMARY = "/public/guest_cart_summary";
export const URL_GET_USER_CART_SUMMARY = "/user/cart_summary";
export const URL_REMOVE_USER_CART_ITEM = "/user/remove_cart_item";
export const URL_FREQUENTLY_BROUGHT_COMBOS =
  "/public/frequently_brought_combos";
export const URL_GET_GST = "/user/get-gst";
export const URL_UPDATE_GST = "/user/update-gst";
export const URL_GENERATE_ORDER = "/user/order/create";
export const URL_UPDATE_STATUS = "/user/order/update";
export const URL_UPDATE_RENEW_STATUS = "/user/order/update/renew-status";
export const URL_RENEW_ORDER = "/user/order/renew";
export const URL_MY_SUBSCRIPTION_LIST = "/user/subscription/";
export const URL_MY_ORDERS = "/user/order/";

export const URL_GET_SOP = "/user/sop/list";
export const URL_GET_SOP_DETAILS = "/user/sop/{id}/details";
export const URL_GET_COMPANY_PROFILE = "/user/company";
export const URL_GET_MY_PROFILE = "/user/profile";
export const URL_UPDATE_MY_PROFILE = "/user/profile";
export const URL_DOWNLOAD_INVOICE = "/user/order/invoice/{id}?order_id=";
export const URL_CHANGE_PASSWORD = "/public/user/change-password";
export const URL_GET_USERS = "/user/users";
export const URL_GET_HISTORY = "/user/sop/{id}/history";
export const URL_GET_ATTACHMENTS = "/user/sop/{id}/attachments";
export const URL_ADD_USER = "/user/add";
export const UPDATE_SOP_DATA = "/user/sop/page/{id}";
export const UPDATE_SOP_PAGE = "/user/sop/page";
export const ACTIVATE_SOP = "/user/sop/active-page?id=";
export const INACTIVATE_SOP = "/user/sop/inactive-page?id=";

export const URI_USER_SOP_ACCESS = "/user/user-sop-access";
export const URI_USER_USERS = "/user/users";
export const URI_USER_ADD = "/user/add";
export const URI_USER_EDIT = "/user/{id}";
export const URI_CHECK_USER_LIMIT = "/user/check-user-limit";
export const URI_USER_SOP_LIST = "/user/sop/list";
export const URI_USER_ACCESS = "/user/sop/access";
export const URI_USER_SOP_USAGE = "/user/sop/sop-usage";
export const URI_USER_SOP_UPDATE_CLICK = "/user/sop/sop-clicks";
export const URI_USER_SOP_ACCEPT_CHANGES = "/user/sop/{id}/accept-changes";
export const URI_USER_SOP_UPDATES = "/user/sop/check-new-sop";

export const URI_LOGIN_SUMMARY = "/user/login-summary";
export const URI_USER_NOTIFICATION = "/user/notification/all";
export const URI_CLEAR_NOTIFICATION = "/user/notification/clear";

export const UPDATE_COMPANY_LOGO = "/user/company-logo?company_id=";
export const URL_GET_SOP_BUNDLE_REVIEW = "/user/sop-bundle/{id}/review";
export const URL_UPDATE_SOP_BUNDLE_REVIEW = "/user/sop-bundle/review";

export const alertType = {
  error: "Error",
  success: "Success",
};
