import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export const useCheckIsLoggedIn = () => {
	const {
		auth: { isAuthenticated },
	} = useAuth();
	if (isAuthenticated) return true;

	const token = localStorage.getItem("token");
	if (token)
		try {
			if (JSON.parse(token)) return true;
		} catch (error) {
			console.log(error);
			return false;
		}

	return false;
};

export const getHeaders = () => {
	const idToken = localStorage.getItem("token");
	if (!idToken) return {};
	const tokenObject = JSON.parse(idToken);
	const token = tokenObject.id_token;
	return {
		Authorization: `Bearer ${token}`,
	};
};

export const clearSession = () => {
	localStorage.clear("token");
	window.location.href = window.location.href;
};
