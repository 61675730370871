import useAuth from "./useAuth";
export const data = {}

const useRazorPay = () => {
    const { auth: { userData } } = useAuth();
    if (!userData) return;

    const options = {
        key: data.key,
        currency: 'INR',
        netbanking: true,
        image: window.location.origin + '/static/media/sop_logo.a0dbb88ccd8a17ab0ee9.png',
        // name: userData['custom:firstname'] + ' ' + userData['custom:lastname'],
        prefill: {
            // name: userData['custom:firstname'] + ' ' + userData['custom:lastname'],
            email: userData.email,
            // contact: '9999999999'
        },
        theme: {
            color: '#00bf63'
        },
    };
    return (payload) => {
        const Razorpay = window.Razorpay;
        const rzp1 = new Razorpay({ ...options, ...payload });
        rzp1.open();
    }
}

export default useRazorPay;