import axios from "axios";
import { getAPIURL } from "../constants";
import { getHeaders } from "./loginUtils";

export const cartData = (callback) => {
    if (localStorage.getItem('token')) {
        const headers = getHeaders();
        axios
            .get(getAPIURL("URL_GET_CART_ITEMS"), { headers })
            .then((response) => {
                callback(response.data);
            })
            .catch((error) => console.error(error));
    }
    else if (sessionStorage.getItem('guestCart') !== null) {
        const pid = sessionStorage.getItem('guestCart').split(',').map(item => item.trim());
        const intArray = pid.map(item => parseInt(item, 10)).filter(item => item !== null);
        const unique = intArray.filter((el, i, arr) => arr.indexOf(el) === i)
        sessionStorage.setItem('guestCart', unique.join(','));
        axios
            .post(getAPIURL("URL_GET_GUEST_CART_ITEMS"), unique)
            .then((response) => {
                callback(response.data);
            })
            .catch((error) => console.error(error));
    }
    else {
        callback([])
    }
}

export const buyNow = (productId, callback) => {
    if (localStorage.getItem('token')) {
        const headers = getHeaders();
        var products = [{ product_master_id: productId }];
        axios
            .post(getAPIURL("URL_ADD_TO_CART"), products, { headers })
            .then((response) => {
                callback(response);
            })
            .catch((error) => console.error(error));
    } else {
        if (sessionStorage.getItem('guestCart') == null) {
            sessionStorage.setItem('guestCart', productId)
        }
        else {
            sessionStorage.setItem('guestCart', sessionStorage.getItem('guestCart') + ',' + productId)
        }
        callback();
    }
}

export const moveGuestCartToUser = (callback) => {
    if (!sessionStorage.getItem('guestCart')) {
        cartData(callback);
        return;
    };

    const pIds = sessionStorage.getItem('guestCart').split(',');

    pIds.forEach((el, i) => buyNow(el, () => {
        if (i === pIds.length - 1) cartData(callback);
        sessionStorage.clear('guestCart')
    }))
}

// to verify GST number
export const isValidGST = (gst) => /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(gst);