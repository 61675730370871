import {
	Row,
	Col,
	Card,
	Button,
	Container,
	Breadcrumb,
	Form,
	Table,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Alert from "react-bootstrap/Alert";
import Footer from "../../layout/Footer";
import Collapse from "react-bootstrap/Collapse";
import ImgLogo from "../../assets/images/sop_logo.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faClockRotateLeft,
	faFloppyDisk,
	faFileCirclePlus,
	faPenToSquare,
	faCircleXmark,
	faCopy,
	faExclamationTriangle,
	faRankingStar,
	faStar,
} from "@fortawesome/free-solid-svg-icons";
import { getAPIURL } from "../../constants";
import { useLoader } from "../../hooks/useLoader";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { getHeaders } from "../../utils/loginUtils";
import { useRole } from "../../hooks/useRole";
import { formatDate, formatTime, formatDateTime } from "../../utils/dateUtils";
import CloneSOP from "./CloneSOP";
import { useIdleTimer } from "react-idle-timer";
import SopReview from "./SopReview";

const colorMap = ["orange", "yellow", "green", "blue", "indigo"];
export const sopData = {};

function MySOP() {
	const navigate = useNavigate();
	const { startLoader, stopLoader } = useLoader();
	const [open, setOpen] = useState(true);
	const [searhStr, setSearchStr] = useState("");
	const [_bundles, setBundles] = useState();
	const [expand, setExpand] = useState({
		bundle: [],
		group: [],
	});
	const [documentData, setDocumentData] = useState();
	const [history, setHistory] = useState();
	const [isEditInProgress, setIsEditInprogress] = useState();
	const [sopHistoryDetails, setSOPHistoryDetails] = useState();
	const [attachments, setAttachments] = useState();
	const [copy, setCopy] = useState();
	const currentDocId = useRef();
	const { isCustomerAdmin } = useRole();
	const [updateData, setUpdateRef] = useState({});
	const [updateBody, setUpdateBody] = useState({});
	const [activeOpenBundle, setActiveOpenBundle] = useState("");
	const [pageState, setPageState] = useState();
	const isCalled = useRef([]);
	const [showModalConfirm, setShowModalConfirm] = useState(false);
	const [updateSummary, setUpdateSummary] = useState(null);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showAlert, setShowAlert] = useState(false);
	const [successMessage, setSuccessMessage] = useState("");
	const [selectedBundleId, setSelectedBundleId] = useState(null);
	const [showReviewModal, setShowReviewModal] = useState(false);
	const processData = (data) =>
		data?.reduce((acc, curr) => {
			return {
				...acc,
				[curr.sop_bundle_id]: {
					name: curr.sop_bundle_title,
					...(acc[curr.sop_bundle_id] || {}),
					[curr.sop_group_id]: {
						name: curr.sop_group_title,
						...(acc?.[curr.sop_bundle_id]?.[curr.sop_group_id] || {}),
						[curr.user_sop_document_id]: {
							name: curr.sop_document_title,
							...(acc?.[curr.sop_bundle_id]?.[curr.sop_group_id]?.[curr.user_sop_document_id] || {}),
						},
					},
				},
			};
		}, {});

	const fetchSops = () => {
		startLoader();
		return axios
			.post(getAPIURL("URL_GET_SOP"), {}, { headers: getHeaders() })
			.then((response) => {
				setBundles(response.data);
			})
			.catch((error) => {
				var error_received = "";
				console.log(error);
				if (error.response) {
					error_received = error.response.data.detail;
				}
				console.log("my error: ", error_received);
			})
			.finally(stopLoader);
	};

	const fetchDataBydocId = (docId, cb) => {
		startLoader();
		axios
			.get(getAPIURL("URL_GET_SOP_DETAILS").replace("{id}", docId), { headers: getHeaders() })
			.then((res) => {
				currentDocId.current = docId;
				setDocumentData(isCustomerAdmin ? res.data : res.data.filter((e) => e.active));
				setUpdateSummary(res.data[0].update_summary);
				addClick(docId);
				if (cb instanceof Function) cb();
			})
			.catch((error) => {
				var error_received = "";
				console.log(error);
				if (error.response) {
					error_received = error.response.data.detail;
				}
				console.log("my error: ", error_received);
			})
			.finally(stopLoader);
	};

	const getHistory = (id) => {
		startLoader();
		axios
			.get(getAPIURL("URL_GET_HISTORY").replace("{id}", id), { headers: getHeaders() })
			.then((res) => {
				setShow(true);
				setHistory(() => res.data);
			})
			.catch((err) => {
				if (err?.response?.status === 404) setHistory(() => ({ error: err?.response?.data?.detail }));
				console.log(err);
				setShow(true);
			})
			.finally(stopLoader);
	};

	const downloadAttachments = () => {
		startLoader();
		axios
			.post(getAPIURL("URL_GET_ATTACHMENTS").replace("{id}", currentDocId.current), {}, { headers: getHeaders() })
			.then((res) => {
				setAttachments(res.data);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(stopLoader);
	};

	const updateUsage = () => {
		axios.post(
			getAPIURL("URI_USER_SOP_USAGE"),
			{
				usage: sopData.usage,
				user_sop_document_id: parseInt(currentDocId.current),
			},
			{ headers: getHeaders() }
		);
	};

	const addClick = (docId) => {
		axios.post(
			getAPIURL("URI_USER_SOP_UPDATE_CLICK"),
			{
				usage: 0,
				user_sop_document_id: parseInt(docId),
			},
			{ headers: getHeaders() }
		);
	};

	const acceptSopChanges = () => {
		return axios
			.post(
				getAPIURL("URI_USER_SOP_ACCEPT_CHANGES").replace("{id}", currentDocId.current),
				{},
				{ headers: getHeaders() }
			)
			.then((response) => {
				setShowModalConfirm(false);
				fetchDataBydocId(currentDocId.current);
				showAlertModal("SOP updated successfully!");
			})
			.catch((error) => {
				var error_received = "";
				console.log(error);
				if (error.response) {
					error_received = error.response.data.detail;
				}
				console.log("my error: ", error_received);
			})
			.finally(stopLoader);
	};

	useEffect(() => {
		fetchSops();
	}, []);

	useIdleTimer({
		timeout: sopData.idletime,
		onAction: () => {
			setPageState("active");
		},
		onIdle: () => {
			setPageState("idle");
			window.confirm("Do you want to continue?");
		},
	});

	const showAlertModal = (message) => {
		setSuccessMessage(message);
		setShowAlert(true);
	};

	/*useEffect(() => {
		let interval;
		if (pageState === "active" && currentDocId.current && documentData) interval = setInterval(updateUsage, 60 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, documentData);*/

	const bundles =
		processData(
			_bundles?.filter(
				(ele) =>
					ele.sop_bundle_title.toLowerCase().includes(searhStr.toLowerCase()) ||
					ele.sop_group_title.toLowerCase().includes(searhStr.toLowerCase()) ||
					ele.sop_document_title.toLowerCase().includes(searhStr.toLowerCase())
			)
		) || {};

	const onSaveClick = (index) => {
		const { created_by, created_at, id, page_num, user_sop_document_id, remarks, sop_header_blocks, sop_body_blocks } =
			documentData.find((el) => el.page_num === index);
		const payload = {
			created_by,
			created_at,
			id,
			page_num,
			user_sop_document_id,
			remarks: updateData?.[index]?.remarks || remarks,
			sop_header_blocks: sop_header_blocks.map((e) => ({
				...e,
				text_content: updateData?.[index]?.[e?.title] || e.text_content,
			})),
			sop_body_blocks: sop_body_blocks.map((e) => ({
				...e,
				text_content: updateBody?.[index]?.[e?.title] || e.text_content,
			})),
		};
		startLoader();
		axios
			.put(getAPIURL("UPDATE_SOP_DATA"), payload, { headers: getHeaders() })
			.then((res) => {
				setIsEditInprogress(false);
				setUpdateBody({});
				setUpdateRef({});
				fetchDataBydocId(currentDocId.current);
			})
			.catch((err) => console.log(err))
			.finally(stopLoader);
	};

	if (!_bundles) return null;

	if (!(_bundles || []).length)
		return (
			<div
				className='special'
				style={{ userSelect: "none" }}
				onSelect={(e) => {
					e.preventDefault();
					return false;
				}}
				onCopy={(e) => {
					e.preventDefault();
					return false;
				}}
				onContextMenu={(e) => {
					e.preventDefault();
					return false;
				}}
			>
				<div
					style={{
						margin: "20px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<div>
						<h5 className='g-0 home-top-padding mb-4'>You do not have any active SOP.</h5>
					</div>
					<div>
						<Button
							onClick={() => {
								navigate("/sop-list");
							}}
						>
							Buy Now
						</Button>
					</div>
				</div>
			</div>
		);

	return (
		<div
			className='special'
			style={{ userSelect: "none" }}
			onSelect={(e) => {
				e.preventDefault();
				return false;
			}}
			onCopy={(e) => {
				e.preventDefault();
				return false;
			}}
			onContextMenu={(e) => {
				e.preventDefault();
				return false;
			}}
		>
			<div className='g-0 home-top-padding mb-4'>
				<div className='innerHead'>
					<h1>My SOP</h1>
				</div>
				<Container fluid='true' className='faq order manageSOP'>
					<Row>
						<Col>
							<Breadcrumb>
								<Breadcrumb.Item href='/Home'>Home</Breadcrumb.Item>
								<Breadcrumb.Item href='/my-sop'>My SOP</Breadcrumb.Item>
								<Breadcrumb.Item>{activeOpenBundle}</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>

					<Row>
						<Col>
							<h4>My SOP(s)</h4>
						</Col>
					</Row>
					<Row className='position-relative'>
						<div className='exp-col-btn'>
							<Button onClick={() => setOpen(!open)} aria-controls='example-collapse-text' aria-expanded={open}>
								<i className='bi bi-chevron-right'></i>
							</Button>
						</div>
						<Collapse in={open}>
							<Col md={3} id='example-collapse-text' className='pe-0 mb-3'>
								<Card>
									<Card.Body>
										<Form className='mb-3'>
											<Form.Control
												type='search'
												placeholder='Search'
												className='search-icon me-2'
												aria-label='Search'
												onChange={(e) => setSearchStr(e.target.value)}
											/>
										</Form>
										<div className='tree-menu-cont'>
											<ul>
												{Object.keys(bundles)?.map((ele, i) => {
													if (i === 0 && !isCalled.current?.[0]) {
														setExpand((prev) => ({
															...prev,
															bundle: prev.bundle.includes(ele)
																? prev.bundle.filter((e) => e !== ele)
																: [...prev.bundle, ele],
														}));
														isCalled.current = [...(isCalled.current || []), true];
													}
													return (
														<>
															<li
																key={ele}
																onClick={() => {
																	setExpand((prev) => ({
																		...prev,
																		bundle: prev.bundle.includes(ele)
																			? prev.bundle.filter((e) => e !== ele)
																			: [...prev.bundle, ele],
																	}));
																}}
															>
																<Row>
																	<Col xs={12} className='tree-main-folder'>
																		<i className='bi bi-folder'></i> {bundles[ele].name}
																		<Button
																			variant='link'
																			style={{ float: "right" }}
																			title='Write review'
																			onClick={() => {
																				console.log(bundles[ele]);
																				var bundleId = Object.keys(bundles[ele]).find((ele) => ele !== "name");
																				setSelectedBundleId(bundleId);
																				setShowReviewModal(true);
																			}}
																		>
																			<FontAwesomeIcon icon={faStar} />
																		</Button>
																	</Col>
																</Row>
															</li>
															{expand.bundle.includes(ele) &&
																Object.keys(bundles[ele])
																	.sort((a, b) => {
																		const first = parseInt(bundles?.[ele]?.[a]?.name?.split(".")?.[0]);
																		const second = parseInt(bundles?.[ele]?.[b]?.name?.split(".")?.[0]);
																		return first - second;
																	})
																	.map((el, ind) => {
																		if (i === 0 && ind === 0 && !isCalled.current?.[1]) {
																			setExpand((prev) => ({
																				...prev,
																				group: prev.group.includes(ele + "-" + el)
																					? prev.group.filter((e) => e !== ele + "-" + el)
																					: [...prev.group, ele + "-" + el],
																			}));
																			isCalled.current = [...isCalled.current, true];
																		}
																		return (
																			el !== "name" && (
																				<ul
																					key={el}
																					className='tree-menu-cont'
																					style={{
																						padding: "1px 5px 1px 20px",
																					}}
																				>
																					<li
																						onClick={() => {
																							setExpand((prev) => ({
																								...prev,
																								group: prev.group.includes(ele + "-" + el)
																									? prev.group.filter((e) => e !== ele + "-" + el)
																									: [...prev.group, ele + "-" + el],
																							}));
																						}}
																						title={bundles[ele][el].name}
																					>
																						<i className='bi bi-folder'></i> {bundles[ele][el].name}
																					</li>
																					{expand.group.includes(ele + "-" + el) &&
																						Object.keys(bundles[ele][el])
																							?.sort((a, b) => {
																								const first = parseInt(
																									bundles?.[ele]?.[el]?.[a]?.name?.split(".")?.[0]
																								);
																								const second = parseInt(
																									bundles?.[ele]?.[el]?.[b]?.name?.split(".")?.[0]
																								);
																								return first - second;
																							})
																							.map((e, index) => {
																								if (i === 0 && ind === 0 && index === 0 && !isCalled.current?.[2]) {
																									fetchDataBydocId(e, updateUsage);
																									setActiveOpenBundle(bundles[ele].name);
																									isCalled.current = [...isCalled.current, true];
																								}

																								return (
																									e !== "name" && (
																										<ul key={e} className='tree-sop-file'>
																											<li
																												onClick={() => {
																													fetchDataBydocId(e);
																													setActiveOpenBundle(bundles[ele].name);
																												}}
																												style={
																													currentDocId.current === e
																														? {
																																fontWeight: "bold",
																																color: "#0d6efd",
																														  }
																														: {}
																												}
																												title={bundles[ele][el][e].name}
																											>
																												<i className='bi bi-file-earmark-text'></i>{" "}
																												{bundles[ele][el][e].name}
																											</li>
																										</ul>
																									)
																								);
																							})}
																				</ul>
																			)
																		);
																	})}
														</>
													);
												})}
											</ul>
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Collapse>
						<Col className='mb-3'>
							{showAlert && (
								<Alert variant='success' onClose={() => setShowAlert(false)} dismissible>
									<p>{successMessage}</p>
								</Alert>
							)}
							{!!documentData?.length &&
								documentData
									?.sort((a, b) => a.page_num - b.page_num)
									?.map((el, i, arr) => (
										<>
											<Card className='mySOP-cont mb-4'>
												<div className='formGroup'>
													<Row>
														<Col className={"mt-1" + (i === 2 ? "mt-5" : "")}>
															Page {i + 1} / {arr.length}
														</Col>
														<Col className='text-end mySOP-controls'>
															<div className='d-inline-flex'>
																{isCustomerAdmin && (
																	<Form.Check
																		type='switch'
																		id='custom-switch'
																		//label="Active"
																		className='mt-1'
																		checked={!!el.active}
																		onChange={(e) => {
																			startLoader();
																			axios
																				.post(
																					getAPIURL(e.target.checked ? "ACTIVATE_SOP" : "INACTIVATE_SOP") + el.id,
																					{},
																					{ headers: getHeaders() }
																				)
																				.then(() => {
																					fetchDataBydocId(currentDocId.current);
																				})
																				.catch((err) => console.log(err))
																				.finally(stopLoader);
																		}}
																	/>
																)}
																{el.page_num == 1 && el.update_summary != null && (
																	<Button
																		variant='link'
																		title='Updates available'
																		onClick={() => setShowModalConfirm(true)}
																	>
																		<FontAwesomeIcon icon={faExclamationTriangle} />
																	</Button>
																)}
																{!isEditInProgress && el.active && (
																	<>
																		<Button variant='link' title='History' onClick={() => getHistory(el.id)}>
																			<FontAwesomeIcon icon={faClockRotateLeft} />
																			{/* <i className="bi bi-cart2"></i> */}
																		</Button>
																		<Button variant='link' title='Download Attachments' onClick={downloadAttachments}>
																			<FontAwesomeIcon icon={faFileCirclePlus} />
																		</Button>
																		{isCustomerAdmin && (
																			<>
																				<Button variant='link'>
																					<FontAwesomeIcon
																						icon={faPenToSquare}
																						title='Edit'
																						onClick={() => {
																							setIsEditInprogress(el.page_num);
																						}}
																					/>
																				</Button>
																				{!(documentData?.length > 1) && (
																					<Button variant='link'>
																						<FontAwesomeIcon
																							icon={faCopy}
																							title='Copy Template'
																							onClick={() => {
																								setCopy(documentData[0]);
																							}}
																						/>
																					</Button>
																				)}
																			</>
																		)}
																	</>
																)}

																{isEditInProgress === el.page_num && (
																	<>
																		<Button variant='link'>
																			<FontAwesomeIcon
																				icon={faCircleXmark}
																				title='Cancel'
																				onClick={() => setIsEditInprogress(false)}
																			/>
																		</Button>
																		<Button variant='link'>
																			<FontAwesomeIcon
																				icon={faFloppyDisk}
																				title='Save'
																				onClick={() => onSaveClick(el.page_num)}
																			/>
																		</Button>
																	</>
																)}
															</div>
														</Col>
													</Row>
												</div>
												{el.active && (
													<Card.Body>
														<Container fluid='true'>
															<Row className='mb-2' key={el?.id}>
																<Col md={10}>
																	<Row>
																		<Col>
																			<Table className='mb-0'>
																				<thead>
																					<tr>
																						{el?.sop_header_blocks?.map((doc) => (
																							<th key={doc.title}>{doc?.title}</th>
																						))}
																					</tr>
																				</thead>
																				<tbody>
																					<tr>
																						{el?.sop_header_blocks?.map((doc) => (
																							<td key={doc.title}>
																								{isEditInProgress === el.page_num &&
																								(el.page_num === 2 ||
																									["Documents", "Responsibility", "Timeline"].includes(doc?.title)) ? (
																									<FloatingLabel label={doc?.title + "*"} className>
																										<Form.Control
																											as='textarea'
																											placeholder={""}
																											style={{
																												minHeight: "200px",
																												height: "100%",
																											}}
																											required
																											defaultValue={doc?.text_content}
																											onChange={(e) => {
																												setUpdateRef((prev) => ({
																													...prev,
																													[el.page_num]: {
																														...(prev?.[el.page_num] || {}),
																														[doc?.title]: e.target.value,
																													},
																												}));
																											}}
																											maxLength={200}
																										/>
																									</FloatingLabel>
																								) : (
																									doc?.text_content
																								)}
																							</td>
																						))}
																					</tr>
																				</tbody>
																			</Table>
																		</Col>
																	</Row>
																</Col>
																<Col md={2} className='ps-0'>
																	<div className='mySOP-logo'>
																		<img className='d-block w-100' src={ImgLogo} alt='Logo' />
																	</div>
																</Col>
															</Row>
															<Row>
																<Col md={10}>
																	<Row>
																		<Col>
																			<div className='steps-head'>{el ? el.body_section_title : ""}</div>
																		</Col>
																	</Row>
																	<Row className='arrow-steps clearfix p-2 me-2'>
																		{el?.sop_body_blocks?.map((e, i) => (
																			<Col className={`step ${colorMap[i % 5]}`} key={i}>
																				<span>{e.title}</span>
																			</Col>
																		))}
																	</Row>
																	<Row>
																		<Col className='mb-2'>
																			<b>Steps to follow / Work instructions</b>
																		</Col>
																	</Row>
																	<Row className='steps-divider me-2'>
																		{el?.sop_body_blocks?.map((e) => (
																			<Col key={e.page_num}>
																				{isEditInProgress === el.page_num && el.page_num === 2 ? (
																					<FloatingLabel label={e?.title + "*"} className>
																						<Form.Control
																							as='textarea'
																							placeholder={""}
																							style={{
																								minHeight: "500px",
																								height: "100%",
																							}}
																							required
																							defaultValue={e?.text_content}
																							onChange={(evt) => {
																								setUpdateBody((prev) => ({
																									...prev,
																									[el.page_num]: {
																										...(prev?.[el.page_num] || {}),
																										[e?.title]: evt.target.value,
																									},
																								}));
																							}}
																							maxLength={200}
																						/>
																					</FloatingLabel>
																				) : (
																					<>
																						<ul>
																							{e.text_content
																								.split("- ")
																								.filter((el) => !!el.trim())
																								.map((el) => (
																									<li>{el.trim()}</li>
																								))}
																						</ul>
																						<Form.Control as='textarea' rows={15} />
																					</>
																				)}
																			</Col>
																		))}
																	</Row>
																</Col>
																<Col md={2} className='ps-0'>
																	<Row>
																		<Col className='mb-2'>
																			<div className='steps-head'>Remarks</div>
																		</Col>
																	</Row>
																	<Row>
																		{!isEditInProgress && <Col className=''>{el.remarks}</Col>}
																		{isEditInProgress === el.page_num && (
																			<FloatingLabel label='Remarks*' className>
																				<Form.Control
																					as='textarea'
																					placeholder='Leave a comment here'
																					style={{
																						minHeight: "200px",
																						height: "100%",
																					}}
																					required
																					defaultValue={el.remarks}
																					onChange={(e) => {
																						setUpdateRef((prev) => ({
																							...prev,
																							[i]: {
																								...(prev?.[i] || {}),
																								remarks: e.target.value,
																							},
																						}));
																					}}
																				/>
																			</FloatingLabel>
																		)}
																	</Row>
																</Col>
															</Row>
														</Container>
													</Card.Body>
												)}
											</Card>
										</>
									))}
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />
			<Offcanvas show={show} onHide={handleClose}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>SOP History Log</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					{history?.error && (
						<Row>
							<Col>
								<h5>{history.error}</h5>
							</Col>
						</Row>
					)}
					{Array.isArray(history) &&
						history?.map((el) => (
							<Row>
								<Col>
									<div>
										<h5>SOP updated</h5>
										{formatDateTime(el.created_at)}
										<br />
										<br />
										<small>
											<a onClick={() => setSOPHistoryDetails(el)} style={{ cursor: "pointer" }}>
												View Details
											</a>
										</small>
									</div>
									<hr></hr>
								</Col>
							</Row>
						))}
				</Offcanvas.Body>
			</Offcanvas>

			<Offcanvas
				show={!!attachments}
				onHide={() => {
					setAttachments(null);
				}}
			>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>SOP Attachments</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					{attachments !== null && !attachments?.length && <h5>No attachments available to download</h5>}
					{Array.isArray(attachments) &&
						attachments?.map((el) => (
							<Row>
								<Col>
									<div>
										<h5>{el.title}</h5>
										{formatDate(el.created_at) + " " + formatTime(el.created_at)}
										<br />
										<br />
										<small>
											<a onClick={() => window.open(el.path)} style={{ cursor: "pointer" }}>
												Download
											</a>
										</small>
									</div>
									<hr></hr>
								</Col>
							</Row>
						))}
				</Offcanvas.Body>
			</Offcanvas>
			<Modal show={showModalConfirm}>
				<Modal.Header closeButton>
					<Modal.Title>SOP Updates</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div dangerouslySetInnerHTML={{ __html: updateSummary }}></div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={() => setShowModalConfirm(false)}>
						Cancel
					</Button>
					<Button variant='primary' onClick={() => acceptSopChanges()}>
						Accept Changes
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={!!sopHistoryDetails} closeButton onHide={() => setSOPHistoryDetails(null)} dialogClassName='w-90'>
				<ModalHeader>
					<h5>
						Details - {formatDate(sopHistoryDetails?.created_at) + " " + formatTime(sopHistoryDetails?.created_at)}
					</h5>
				</ModalHeader>
				<ModalBody>
					<>
						<Row className='mb-2'>
							<Col md={10}>
								<Row>
									<Col>
										<Table className='mb-0'>
											<thead>
												<tr>
													{sopHistoryDetails?.header &&
														JSON.parse(sopHistoryDetails?.header)?.map((doc) => <th key={doc.title}>{doc?.title}</th>)}
												</tr>
											</thead>
											<tbody>
												<tr>
													{sopHistoryDetails?.header &&
														JSON.parse(sopHistoryDetails?.header)?.map((doc) => (
															<td key={doc.title}>{doc?.text_content}</td>
														))}
												</tr>
											</tbody>
										</Table>
									</Col>
								</Row>
							</Col>
							<Col md={2} className='ps-0'>
								<div className='mySOP-logo'>
									<img className='d-block w-100' src={ImgLogo} alt='Logo' />
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={10}>
								<Row className='arrow-steps clearfix p-2 me-2'>
									{sopHistoryDetails?.body &&
										JSON.parse(sopHistoryDetails?.body)?.map((e, i) => (
											<Col className={`step ${colorMap[i % 5]}`} key={i}>
												<span>{e.title}</span>
											</Col>
										))}
								</Row>
								<Row>
									<Col className='mb-2'>
										<b>Steps to follow / Work instructions</b>
									</Col>
								</Row>
								<Row className='steps-divider'>
									{sopHistoryDetails?.body &&
										JSON.parse(sopHistoryDetails?.body)?.map((e, i) => (
											<Col key={i}>
												<ul>
													{e.text_content
														.split("-")
														.filter((el) => !!el.trim())
														.map((el) => (
															<li>{el.trim()}</li>
														))}
												</ul>
											</Col>
										))}
								</Row>
							</Col>
							<Col md={2} className='ps-0'>
								<Row>
									<Col className='mb-2'>
										<div className='steps-head'>Remarks</div>
									</Col>
								</Row>
								<Row>
									<Col className=''>{sopHistoryDetails?.remarks}</Col>
								</Row>
							</Col>
						</Row>
					</>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => setSOPHistoryDetails(null)}>Close</Button>
				</ModalFooter>
			</Modal>
			<CloneSOP
				docData={copy}
				onCancel={() => setCopy(null)}
				onSaveSuccess={() => fetchDataBydocId(currentDocId.current)}
				otherData={{ ImgLogo, colorMap }}
			/>
			{showReviewModal && (
				<SopReview
					bundleId={selectedBundleId}
					onClose={() => {
						setShowReviewModal(false);
					}}
					showAlertModal={(message) => {
						showAlertModal(message);
					}}
				/>
			)}
		</div>
	);
}

export default MySOP;
