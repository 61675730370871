import useAuth from "./useAuth";

export const useRole = () => {
    const { auth: { userData } } = useAuth();
    const isCustomerAdmin = userData?.role === 'customer-admin'

    return {
        role: userData?.role,
        isCustomerAdmin
    }

}
