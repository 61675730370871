import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, FloatingLabel, Form } from "react-bootstrap";
import axios from "axios";
import { getAPIURL } from "../../constants";
import { getHeaders } from "../../utils/loginUtils";
import { Rating } from "react-simple-star-rating";

function SopReview({ bundleId, onClose, showAlertModal }) {
	const [selectedBundleId, setSelectedBundleId] = useState(bundleId);
	const [feedback, setFeedback] = useState("");
	const [rating, setRating] = useState(0);

	useEffect(() => {
		axios
			.get(getAPIURL("URL_GET_SOP_BUNDLE_REVIEW").replace("{id}", selectedBundleId), { headers: getHeaders() })
			.then((response) => {
				console.log(response);
				if (response != null) {
					setFeedback(response.data.comment);
					setRating(response.data.rating);
				}
			})
			.catch((error) => {
				/*var error_received = "";
				console.log(error);
				if (error.response) {
					error_received = error.response.data.detail;
				}
				console.log("my error: ", error_received);*/
			})
			.finally(() => {});
	}, []);

	const handleSubmit = () => {
		axios
			.post(
				getAPIURL("URL_UPDATE_SOP_BUNDLE_REVIEW"),
				{
					user_sop_bundle_id: selectedBundleId,
					comment: feedback,
					rating: rating,
				},
				{ headers: getHeaders() }
			)
			.then((response) => {
				onClose();
				showAlertModal("Review updated successfully!");
			})
			.catch((error) => {
				/*var error_received = "";
				console.log(error);
				if (error.response) {
					error_received = error.response.data.detail;
				}
				console.log("my error: ", error_received);*/
			})
			.finally(() => {});
	};
	return (
		<Modal show={true} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Write a review</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xs={12}>
						<Rating size={20} initialValue={rating} onClick={(rating) => setRating(rating)} />
					</Col>
				</Row>
				<Row>
					<Col>
						<div>&nbsp;</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<FloatingLabel label='Comment' className='mb-3'>
							<Form.Control
								as='textarea'
								rows={10}
								value={feedback}
								name='feedback'
								maxlength='500'
								onChange={(e) => setFeedback(e.target.value)}
							/>
						</FloatingLabel>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={onClose}>
					Cancel
				</Button>
				<Button variant='primary' onClick={handleSubmit}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default SopReview;
