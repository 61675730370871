import React, { useEffect } from "react";
import axios from "axios";
import { clearSession } from "../utils/loginUtils";

const checkRequests = (Wrapped) => {
	function CheckRequests(props) {
		useEffect(() => {
			axios.interceptors.response.use(
				function (response) {
					// Do something with response data
					return response;
				},
				function (error) {
					switch (error.response.status) {
						case 403:
							//clearSession();
							break;
						default:
							break;
					}
					// Do something with response error
					return Promise.reject(error);
				}
			);
		});

		return <Wrapped {...props} />;
	}
	return CheckRequests;
};

export default checkRequests;
